<template>
  <b-row
    align-h="center"
    class="mt-5"
  >
    <b-col md="8">
      <b-card header="Change Password">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(changePassword)">
            <validation-provider
              #default="validationContext"
              name="Current Password"
              :rules="`required|is_not:${newPassword.new_password}`"
            >
              <b-form-group
                label="Current Password"
                label-for="current_password"
              >
                <b-form-input
                  id="email"
                  v-model="newPassword.current_password"
                  placeholder="Current Password"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="New Password"
              rules="required|password"
              vid="confirmed"
            >
              <b-form-group
                label="New Password"
                label-for="new_password"
              >
                <b-form-input
                  id="new_password"
                  v-model="newPassword.new_password"
                  placeholder="New Password"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|confirmed:confirmed"
            >
              <b-form-group
                label="Confirm Password"
                label-for="new_password_confirmed"
              >
                <b-form-input
                  id="new_password_confirmed"
                  v-model="newPassword.new_password_confirmed"
                  placeholder="Confirm Password"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-button
              variant="primary"
              type="submit"
            >
              Change
            </b-button>
            <back />
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'ChangePassword',
  components: {
    Back,
  },
  data() {
    return {
      newPassword: {},
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()

    const {
      getValidationState,
    } = formValidation()

    return {
      successfulOperationAlert, showErrors, getValidationState,
    }
  },
  methods: {
    changePassword() {
      this.$portalUsers.post('v1/change-password', this.newPassword).then(() => {
        this.successfulOperationAlert('Password is changed successfully')
      }).catch(err => {
        this.showErrors(err.response.data.meta)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
